<template>
  <div>
    <b-row>
      <b-col>
        <h3>Uusi tilaus</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="registration" md="6" v-if="!isLoggedIn">
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="email">Vuokraus alkaa<span class="mndt">*</span>:</label>
          </b-col>
          <b-col sm="8">
            <datepicker
              :bootstrap-styling="true"
              input-class="form-control-sm"
              :monday-first="true"
              :language="fi"
              :format="date_format"
              :value="start_date"
              @selected="startDateChange"
              :disabled-dates="disabledStartDates"
            />
            <b-form-invalid-feedback id="email">
              Поле не может быть пустым
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="email">Vuokraus loppu<span class="mndt">*</span>:</label>
          </b-col>
          <b-col sm="8">
            <datepicker
              :bootstrap-styling="true"
              input-class="form-control-sm"
              :monday-first="true"
              :language="fi"
              :format="date_format"
              :value="end_date"
              @selected="endDateChange"
              :disabled-dates="disabledEndDates"
            />
            <b-form-invalid-feedback id="email">
              Поле не может быть пустым
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="email">Sähköpostiosoite<span class="mndt">*</span>:</label>
          </b-col>
          <b-col sm="8">
            <b-form-input id="email" :state="checkValid('email')" size="sm" v-model="email" />
            <b-form-invalid-feedback id="email">
              Поле не может быть пустым
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="first_name">Etunimi<span class="mndt">*</span>:</label>
          </b-col>
          <b-col sm="8">
            <b-form-input id="first_name" :state="checkValid('first_name')" size="sm" v-model="first_name" />
            <b-form-invalid-feedback id="first_name">
              Поле не может быть пустым
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="last_name">Sukunimi<span class="mndt">*</span>:</label>
          </b-col>
          <b-col sm="8">
            <b-form-input id="last_name" :state="checkValid('last_name')" size="sm" v-model="last_name" />
            <b-form-invalid-feedback id="last_name">
              Поле не может быть пустым
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="phone">Puhelinnumero<span class="mndt">*</span>:</label>
          </b-col>
          <b-col sm="8">
            <b-form-input id="phone" :state="checkValid('phone')" size="sm" v-model="phone" />
            <b-form-invalid-feedback id="phone">
              Поле не может быть пустым
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="city">Kaupunki<span class="mndt">*</span>:</label>
          </b-col>
          <b-col sm="8">
            <b-form-input id="city" :state="checkValid('city')" size="sm" v-model="city" />
            <b-form-invalid-feedback id="city">
              Поле не может быть пустым
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="postal_code">Postinumero<span class="mndt">*</span>:</label>
          </b-col>
          <b-col sm="8">
            <b-form-input id="postal_code" :state="checkValid('postal_code')" size="sm" v-model="postal_code" />
            <b-form-invalid-feedback id="postal_code">
              Поле не может быть пустым
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="address">Osoite<span class="mndt">*</span>:</label>
          </b-col>
          <b-col sm="8">
            <b-form-input id="address" :state="checkValid('address')" size="sm" v-model="address" />
            <b-form-invalid-feedback id="address">
              Поле не может быть пустым
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row class="mb-2">
          <b-col>
            <div class="order_sum text-center">
              Yhteensä: <strong>{{ order_sum }} EUR</strong>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="4">
            <label for="note">Kommentit:</label>
          </b-col>
          <b-col sm="8">
            <b-textarea
              id="note"
              size="sm"
              rows="4"
              max-rows="6"
              v-model="note"
            ></b-textarea>
          </b-col>
        </b-row>
        <div class="text-right">
          <small v-if="lang==='ru'">
            Оставьте свои контактные данные и мы свяжемся с Вами.
          </small>
          <small v-if="lang==='fi'">
            Jätä yhteystietosi, niin otamme sinuun yhteyttä.
          </small>
          <br />
          <b-button
            :disabled="isDisabled"
            variant="primary"
            @click="confirm"
          >
            Lähetä tilaus
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { differenceInCalendarDays, format } from 'date-fns';
import Datepicker from "vuejs-datepicker";
import { fi } from "vuejs-datepicker/dist/locale";

export default {
  name: "Registration",
  components: {
    Datepicker
  },
  props: ["isLoggedIn", "isDisabled", "sendOrder", "itm_price"],
  data: () => ({
    disabledStartDates: null,
    disabledEndDates: null,
    start_date: null,
    end_date: null,
    dates_diff: 1,
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    city: "",
    postal_code: "",
    address: "",
    note: "",
    order_sum: 0.0,
    fi: fi,
    date_format: "dd-MM-yyyy",
    validation_errors: []
  }),
  computed: {
    ...mapGetters({
      loading: "loader/loading",
      isLoggedIn: "user/isLoggedIn",
      role: "user/role",
      username: "user/username",
      is_desktop: "app/is_desktop",
      lang: "settings/lang"
    }),
    disabled_til() {
      const dt = new Date();
      dt.setDate(dt.getDate() - 1);
      return dt;
    }
  },
  watch: {
    email(newVal) {
      if (newVal === "") {
        this.validation_errors.push("email");
      } else {
        this.validation_errors = this.validation_errors.filter( k => k !== "email");
      }
    },
    first_name(newVal) {
      if (newVal === "") {
        this.validation_errors.push("first_name");
      } else {
        this.validation_errors = this.validation_errors.filter( k => k !== "first_name");
      }
    },
    last_name(newVal) {
      if (newVal === "") {
        this.validation_errors.push("last_name");
      } else {
        this.validation_errors = this.validation_errors.filter( k => k !== "last_name");
      }
    },
    phone(newVal) {
      if (newVal === "") {
        this.validation_errors.push("phone");
      } else {
        this.validation_errors = this.validation_errors.filter( k => k !== "phone");
      }
    },
    city(newVal) {
      if (newVal === "") {
        this.validation_errors.push("city");
      } else {
        this.validation_errors = this.validation_errors.filter( k => k !== "city");
      }
    },
    postal_code(newVal) {
      if (newVal === "") {
        this.validation_errors.push("postal_code");
      } else {
        this.validation_errors = this.validation_errors.filter( k => k !== "postal_code");
      }
    },
    address(newVal) {
      if (newVal === "") {
        this.validation_errors.push("address");
      } else {
        this.validation_errors = this.validation_errors.filter( k => k !== "address");
      }
    },
  },
  created() {
    this.start_date = new Date();
    this.end_date = new Date();
    this.end_date.setDate(this.end_date.getDate() + 1);
    this.order_sum = this.itm_price;

    this.disabledStartDates = {
      to: this.disabled_til
    }

    this.disabledEndDates = {
      to: this.start_date
    }

  },
  methods: {
    startDateChange(dt) {
      this.start_date = dt;
      const edt = new Date();
      this.end_date = edt.setDate(dt.getDate() + 1);
      this.disabledEndDates = {
        to: edt
      }
      this.order_sum = this.itm_price;
      this.dates_diff = 1;
    },
    endDateChange(dt) {
      this.end_date = dt;
      const dates_diff = differenceInCalendarDays(dt, this.start_date);
      this.order_sum = dates_diff * this.itm_price;
      this.dates_diff = dates_diff;
    },
    checkValid(key) {
      if (this.validation_errors.length === 0) return null;
      if (this.validation_errors.includes(key)) return false;
      if (!(this.validation_errors.includes(key))) return true;
    },
    validate(payload) {
      let not_empty = true;
      let errors = [];
      const keys = Object.keys(payload).filter(k => k !== "note");
      keys.map(k => {
        if (payload[k] === "") {
          errors.push(k);
          not_empty = false;
        }
      });
      this.validation_errors = [...errors];
      return not_empty;
    },
    confirm() {
      if (this.isLoggedIn) {
        this.sendOrder({ note: this.note });
      } else {
        const payload = {
          email: this.email,
          first_name: this.first_name,
          last_name: this.last_name,
          phone: this.phone,
          city: this.city,
          postal_code: this.postal_code,
          address: this.address,
          note: this.note,
          start_date: format(this.start_date, "yyyy-MM-dd"),
          end_date: format(this.end_date, "yyyy-MM-dd"),
          dates_diff: this.dates_diff,
          order_sum: this.order_sum,
          price: this.itm_price
        };

        const isValid = this.validate(payload);
        if (isValid) {
          this.sendOrder(payload);
        }
      }


    }
  }
};
</script>

<style lang="sass" scoped>
.order_sum
  margin-bottom: 32px
  padding: 16px
  background-color: #fff
  border: 1px solid #e2e2e2
  border-radius: 4px
  // font-weight: 700
  font-size: 1.3rem
.registration
  margin-bottom: 32px
</style>