import Vue from "vue";
import store from "@/store";
import Api from "./api";

let LANG = "/fi";

export default {
  getData(entity, id, query = {}, lang = LANG, skip_lang = false) {
    const query_string = Object.keys(query)
      .reduce(function(a, k) {
        a.push(k + "=" + encodeURIComponent(query[k]));
        return a;
      }, [])
      .join("&");

    if (skip_lang) lang = "";

    if (id)
      return Api()
        .get(`${lang}/api/${entity}/${id}/`)
        .catch(error => errorHandler(error));
    return Api()
      .get(`${lang}/api/${entity}/?${query_string}`)
      .catch(error => errorHandler(error));
  },
  setData(entity, id, payload, lang = LANG, skip_lang = false) {
    if (skip_lang) lang = "";

    if (id)
      return Api()
        .put(`${lang}/api/${entity}/${id}/`, payload)
        .catch(error => errorHandler(error));
      return Api()
        .post(`${lang}/api/${entity}/`, payload)
        .catch(error => errorHandler(error));
    // const config = {
    //   method: 'post',
    //   url: `${LANG}/api/${entity}/`,
    //   headers: {
    //   },
    //   data : payload
    // };

    // return Api()(config)
    //   .then(
    //     response => console.log(response)
    //     // error => errorHandler(error)
    //   );
  },
  delData(entity, payload) {
    return Api()
      .post(`${LANG}/api/${entity}/`, { ...payload, action: "delete" })
      .catch(error => errorHandler(error));
  },
  uploadFile(formData) {
    return Api()
      .post(`${LANG}/api/files/upload/`, formData)
      // get data
      .then(x => x.data);
  },
  uploadSlide(formData) {
    return Api()
      .post(`${LANG}/api/slides/upload/`, formData)
      // get data
      .then(x => x.data);
  }

};

function errorHandler(error) {
  if (error.response.status === 401) {
    delete Vue.axios.defaults.headers.common["Authorization"];
    store.dispatch("user/logout");
  }
}