import Api from "@/services/general";

// initial state
const state = {};

const getters = {};

const actions = {
  async sendOrder(ctx, payload) {
    const res = await Api.setData("create_order", null, payload);
    return { status: res.data.message.type };
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
