import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";
import createLogger from "vuex/dist/logger";

import app from "./modules/app";
import catalog from "./modules/catalog";
import content from "./modules/content";
import loader from "./modules/loader";
import order from "./modules/order";

import settings from "./modules/settings";
// import user from "./modules/user";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    app,
    catalog,
    content,
    loader,
    order,
    settings,

    // user
  },
  strict: debug,
  plugins: debug
    ? [createLogger()] //, createPersistedState()
    : [] //createPersistedState()
});
