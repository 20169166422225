import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import axios from "axios";
// import VueAxios from "vue-axios";
// import vSelect from "vue-select";
import BootstrapVue from "bootstrap-vue";

Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);

Vue.config.productionTip = false;

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/styles/styler.sass";

new Vue({
  router,
  store,
  beforeCreate() {
    store.dispatch("settings/getSettings").then(() => {});
    // store.dispatch("content/getNavigation").then(() => {});
  },
  render: (h) => h(App),
}).$mount("#app");
