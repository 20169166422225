import { isMobile } from "mobile-device-detect";
import { v4 as uuidv4 } from "uuid";

// initial state
const state = {
  messages: []
};

const getters = {
  messages: (state) => state.messages,
  is_desktop: () => !isMobile
};

const actions = {
  clearMessages() {
    this.commit("app/clearMessages");
  },
  closeMessage(ctx, message_id) {
    this.commit("app/closeMessage", message_id);
  }
};

const mutations = {
  setMessage(state, message) {
    const msg = state.messages.find((msg) => msg.text === message.text);
    if (!msg)
      state.messages = [{ ...message, id: uuidv4() }, ...state.messages];
  },
  clearMessages(state) {
    state.messages = [];
  },
  closeMessage(state, message_id) {
    state.messages = state.messages.filter((item) => item.id !== message_id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
