<template>
  <div>
    <b-row>
      <b-col>
        <Items />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="page">
        <p class="text" v-html="page.page_desc"> </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Items from "@/components/catalog/Items";

export default {
  name: "Home",
  components: {
    Items,
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      is_desktop: "app/is_desktop",
      page: "content/page",
    }),
  },
  created() {
    this.$store.dispatch("content/getPage", {slug: "main"});
  }
};
</script>
