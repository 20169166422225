import router from "@/router";
import Api from "@/services/general";

const state = {
  settings: [],
  lang: "fi"
};

const getters = {
  lang: () => state.lang,
  settings: state => state.settings,
  packing_price: state => {
    const st = state.settings.find(s => s.set_key === "packing_price");
    if (st) return Number(st.set_val);
    return 0;
  },
  payment_lock: state => {
    const st = state.settings.find(s => s.set_key === "payment_lock");
    if (st) return !!Number(st.set_val);
    return false;
  }
};

const actions = {
  async setLanguage(ctx, payload) {
    const res = await Api.setData("set_language", null, payload, null, true);
    if (res.data.status === "ok") {
      this.commit("settings/setLanguage", payload.lang);
      localStorage.setItem("lang", payload.lang);
      router.go();
    }
  },
  async getSettings() {
    const res = await Api.getData("app_settings");
    this.commit("settings/setSettings", res.data.settings);
    this.commit("settings/setLanguage", res.data.lang);
  },
};

const mutations = {
  setLanguage(state, payload) {
    state.lang = payload;
  },
  setSettings(state, payload) {
    state.settings = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
