<template>
  <div class="item">
    <router-link :to="item_card">
      <div class="image">
        <img v-if="item.itm_pic" v-show="item.itm_pic" :src="item.itm_pic" class="img-preview" width="326" />
        <div v-else class="placeholder">no image</div>
      </div>
      <div class="desc">
        <div class="info">
          <div class="title">
            {{ item.itm_name }}
          </div>
          <div class="short_desc">
            {{ item.itm_short_desc }}
          </div>
        </div>
        <div class="space">
          {{ item.itm_space }} m<sup>2</sup>
        </div>
      </div>
    </router-link>
    <div class="price">
      <span class="reg_price"><strong>€</strong> {{ item.itm_price }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Item",
  props: ["item", "is_desktop"],
  data: () => ({}),
  computed: {
    item_card() {
      return `/i/${this.item.id}`;
    },
    btn_size() {
      return this.is_desktop ? 'sm' : '';
    },
    // thumbnail() {
    //   if (this.prod.images.length === 0) return;
    //   return this.prod.images[0].smallURL;
    // }
  },
  created() {},
  methods: {}
};
</script>

<style lang="sass" scoped>
.variants
  display: flex
  .label
    margin-right: 9px

.to_cart
  display: flex
  align-items: center
  justify-content: flex-start
  .b-form-spinbutton
    width: auto

  &.no_weight
    display: block
    text-align: center

.price
  margin-top: 5px
  padding-top: 5px
  border-top: 1px solid #e2e2e2
  display: flex
  justify-content: flex-end
  align-items: center
  // flex-wrap: wrap

.alert_price, .reg_price
  margin: 0 10px
  font-size: 1.3rem
  font-weight: bold

.reg_price


.alert_price
  color: red

.old_price
  font-weight: 400
  position: relative
  &:before
    content: ""
    position: absolute
    top: 50%
    width: 110%
    height: 1px
    display: block
    background: #f91155
    transform: rotate(-10deg)
</style>
