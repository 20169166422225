<template>
  <div v-if="!loading" class="fixed-top">
    <div class="text-center top-line">
      +358 40 512 52 32 / reipas@sapokka.fi
    </div>

    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand href="#">
        <strong>REIPAS</strong>
        <div>Accommodations</div>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item v-if="lang==='fi'" to="/">Asunnot</b-nav-item>
          <b-nav-item v-if="lang==='ru'" to="/">Квартиры</b-nav-item>
          <!-- <b-nav-item to="/u/cart">Cart <b-badge variant="light">{{ quan }} / {{ (sum).toFixed(2) }} EUR / {{ (weight).toFixed(2) }} кг.</b-badge></b-nav-item> -->

          <!-- <b-nav-item-dropdown v-if="isLoggedIn && role==='user'" text="My orders" right>
            <b-dropdown-item to="/u/orders">Orders</b-dropdown-item>
          </b-nav-item-dropdown> -->

        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item v-if="lang==='fi'" to="/p/varausehdot">Varausehdot</b-nav-item>
          <b-nav-item v-if="lang==='ru'" to="/p/varausehdot">Условия</b-nav-item>
          <!-- <b-nav-item to="/u/cart">Cart <b-badge variant="light">{{ quan }} / {{ (sum).toFixed(2) }} EUR / {{ (weight).toFixed(2) }} кг.</b-badge></b-nav-item> -->

          <!-- <b-nav-item-dropdown v-if="isLoggedIn && role==='user'" text="My orders" right>
            <b-dropdown-item to="/u/orders">Orders</b-dropdown-item>
          </b-nav-item-dropdown> -->

        </b-navbar-nav>
        <b-navbar-nav>
          <img :src="fi" class="lang" @click="setLang('fi')">
        </b-navbar-nav>
        <b-navbar-nav>
          <img :src="ru" class="lang" @click="setLang('ru')">
        </b-navbar-nav>
        <b-navbar-nav>
          <div class="ml-3" v-if="!isLoggedIn">
            <b-nav-item class="login_button_container" to="/u/login">
              <span v-if="lang==='fi'" class="btn btn-sm btn-primary">Kirjaudu <i class="fas fa-sign-in-alt"></i></span>
              <span v-if="lang==='ru'" class="btn btn-sm btn-primary">Войти <i class="fas fa-sign-in-alt"></i></span>
            </b-nav-item>
          </div>
          <div class="ml-3" v-else>
            <div class="logout">
              <div class="username"><i class="fas fa-user-alt"></i> {{ username }}</div>
              <button class="btn btn-sm btn-warning" @click="logout">Выйти <i class="fas fa-sign-out-alt"></i></button>
            </div>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Header",
  components: {
  },
  props: [],
  data: () => ({
    fi: require("../../assets/images/fi.png"),
    ru: require("../../assets/images/ru.png"),
  }),
  computed: {
    ...mapGetters({
      loading: "loader/loading",
      isLoggedIn: "user/isLoggedIn",
      role: "user/role",
      username: "user/username",
      is_desktop: "app/is_desktop",
      lang: "settings/lang"
    })
  },
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
    },
    setLang(lang) {
      this.$store.dispatch("settings/setLanguage", { lang });
    }
  },
  created() {}
};
</script>

<style lang="sass">
.lang
  max-width: 16px
  margin-right: 8px
  &:hover
    cursor: pointer

.navbar-brand
  font-size: 1.1rem !important
  // font-family: Courier New, Courier, monospace

nav
  margin-bottom: 20px
.logout
  display: flex
  button
    margin-left: 8px
  .username
    padding-top: 4px
    color: #fff

</style>
