<template>
  <div>
    <b-row v-if="loading">
      <b-col>
        <div class="text-center">
          <b-spinner
            style="width: 3rem; height: 3rem;"
            variant="primary"
            label="Loading"/>
        </div>
      </b-col>
    </b-row>
    <div v-else>
      <b-row>
        <b-col>
          <PageTitle :title="item.itm_name" back="true" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="item_card">
            <div class="image text-center">
              <img
                v-if="activeImage"
                v-show="activeImage"
                :src="activeImage"
                class="img-preview"
              />
              <div v-else class="placeholder">no image</div>
            </div>
            <div class="images">
              <div>
                <img
                  :src="item.itm_pic"
                  class="thumbnail"
                  @click="setActive(item.itm_pic)"
                />
              </div>
              <div v-for="pic in item.pics"
                :pic="pic"
                :key="pic.pic"
                >
                <img
                  :src="pic.pic"
                  class="thumbnail"
                  @click="setActive(pic.pic)"
                />
              </div>
            </div>
            <div class="details" v-if="is_desktop">
              <div class="dw">
                <div class="size">
                  {{ item.itm_space }} m<sup>2</sup>
                </div>
                <div class="info">
                  <ul>
                    <li>{{ item.itm_short_desc }}</li>
                  </ul>
                </div>
                <div class="price">
                  € {{ item.itm_price }}
                </div>
              </div>
              <div class="params" v-if="item.params">
                <div v-for="p in item.params"
                    :p="p"
                    :key="p.id"
                    >{{ p.prm_name }}
                </div>
              </div>
            </div>
            <div class="details_m" v-else>
              <div class="dw">
                <div class="size_m">
                  {{ item.itm_space }} m<sup>2</sup>
                </div>
                <div class="price">
                  € {{ item.itm_price }}
                </div>
              </div>

              <div class="info text-center">
                <ul>
                  <li>{{ item.itm_short_desc }}</li>
                </ul>
              </div>

              <div class="params text-center" v-if="item.params && is_desktop">
                <div v-for="p in item.params"
                    :p="p"
                    :key="p.id"
                    >{{ p.prm_name }}
                </div>
              </div>
              <div class="text-center" v-if="item.params && !is_desktop">
                <div v-for="p in item.params"
                    :p="p"
                    :key="p.id"
                    >{{ p.prm_name }}
                </div>
              </div>
            </div>

            <div class="desc">
              <p class="text" v-html="item.itm_desc"> </p>
            </div>
          </div>
        </b-col>
      </b-row>
      <div v-if="showComplete">
        <b-row>
          <b-col>
            <h3>Kiitos tilauksesta!</h3>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <Registration :sendOrder="sendOrder" :itm_price="item.itm_price" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageTitle from "@/components/generic/PageTitle";
import Registration from "@/components/generic/Registration";

export default {
  name: "ItemCard",
  components: {
    PageTitle,
    Registration
  },
  data: () => ({
    loading: true,
    activeImage: null,
    orderDisabled: false,
    showComplete: false,
  }),
  computed: {
    ...mapGetters({
      is_desktop: "app/is_desktop",
      item: "catalog/item",
    }),
  },
  methods: {
    setActive(pic) {
      this.activeImage = pic;
    },
    sendOrder(payload) {
      this.orderDisabled = true;
      // if (this.isLoggedIn) {
      //   this.$store
      //     .dispatch("cart/sendLinkOrder", { // DNT!!!
      //       ...regData,
      //       address_id: this.selected_address.id,
      //       dlv_price: this.rate ? this.total_delivery : 0
      //     })
      //     .then(({status, order_id}) => {
      //       if (status === "success") {
      //         router.push({ path: `/` });
      //       }
      //       this.order_disabled = false;
      //     });
      // } else {
        // if (isValid) {
      this.$store
        .dispatch("order/sendOrder", {
          ...payload,
          item_id: this.item.id
        })
        .then(({ status }) => {
          if (status === "success") {
            this.showComplete = true;
            // localStorage.removeItem("cart_link");
            // router.push({ path: `/` });
          };
          this.orderDisabled = false;
        });
        //} //isValid
    }
  },
  created() {
    if (this.$route.params.id) {
      this.$store
        .dispatch("catalog/getItem", { id: this.$route.params.id })
        .then(() => {
          this.loading = false;
          this.activeImage = this.item.itm_pic;
      });
    }
  }
}
</script>

<style lang="sass">
  .dw, .params
    display: flex
    justify-content: center
    align-items: center

  .details, .details_m
    margin: 16px 0
    padding: 8px
    background-color: #fff
    border-radius: 4px
    box-shadow: 0 1px 0 0 rgb(0 0 0 / 6%), 0 0 100px 0 rgb(0 0 0 / 10%)
    div
      margin-right: 20px

  .details_m
    div:not(.size_m)
      margin-right: 0


  .images
    margin-top: 16px
    border-top: 1px solid #ccc
    padding: 16px 4px 16px 4px
    display: flex
    align-items: center
    overflow: auto

  .image
    img
      width: 100%

  .thumbnail
    max-width: 240px
    max-height: 160px
    margin-right: 16px
    border: 1px solid #fff
    border-radius: 4px
    box-shadow: 0 1px 0 0 rgb(0 0 0 / 6%), 0 0 100px 0 rgb(0 0 0 / 10%)
    transition: box-shadow .2s ease-in-out

    &:hover
      box-shadow: 0 5px 15px 0 #ccc
      cursor: pointer

// @media (max-width: 688px)
//   .image
//     img
//       width: 100%

</style>