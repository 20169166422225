import Api from "@/services/general";

const state = {
  items: [],
  item: null
};

const getters = {
  item: (state) => state.item,
  items: (state) => state.items,
};

const actions = {
  async getItems() {
    const lang = localStorage.getItem("lang") || "fi";
    const res = await Api.getData("items", null, {}, lang);
    this.commit("catalog/setItems", res.data);
  },
  async getItem(ctx, payload) {
    const lang = localStorage.getItem("lang") || "fi";
    const res = await Api.getData("item", payload.id, {}, lang);
    this.commit("catalog/setItem", res.data);
  },
};

const mutations = {
  setItems(state, payload) {
    state.items = payload;
  },
  setItem(state, payload) {
    state.item = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
