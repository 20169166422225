import Api from "@/services/general";

const state = {
  all_pages: [],
  // navigation: [],
  page: null,

  all_slides: [],
  slides: [],
};

const getters = {
  page: (state) => state.page,
  // navigation: (state) => state.navigation,
  all_pages: (state) => state.all_pages,

  slide: (state) => state.slide,
  slides: (state) => state.slides,
  all_slides: (state) => state.all_slides,
};

const actions = {
  async createPage(ctx, payload) {
    const pl = { ...payload };
    let res = null;
    if (payload.id !== null) {
      delete pl.id;
      res = await Api.setData("a/page", payload.id, pl);
    } else {
      res = await Api.setData("a/page", null, pl);
    }
    this.commit("content/updatePages", res.data.page);
  },
  async getAllPages() {
    const res = await Api.getData("a/pages", null);
    this.commit("content/setAllPages", res.data);
  },
  // async getNavigation() {
  //   const res = await Api.getData("navigation", null);
  //   this.commit("content/setNavigation", res.data);
  // },
  async getPage(ctx, payload) {
    const lang = localStorage.getItem("lang") || "fi";
    const res = await Api.getData(`page`, payload.slug, {}, lang);
    this.commit("content/setPage", res.data);
  },

  async createSlide(ctx, payload) {
    const res = await Api.setData("a/slide", null, payload);
    this.commit("content/updateSlides", res.data.slide);
  },
  async updateSlide(ctx, payload) {
    const { id, ...rest } = payload;
    const res = await Api.setData("a/slide", id, rest);
    this.commit("content/updateSlides", res.data.slide);
  },
  async delImage(ctx, id) {
    const res = await Api.delData("slide_image", { id });
    if (res.data) return res.data.status;
  },
  async getAllSlides() {
    const res = await Api.getData("a/slides", null);
    this.commit("content/setAllSlides", res.data);
  },
  async getSlides() {
    const res = await Api.getData("slides", null);
    this.commit("content/setSlides", res.data);
  },
};

const mutations = {
  setAllPages(state, payload) {
    state.all_pages = [...payload];
  },
  // setNavigation(state, payload) {
  //   state.navigation = [...payload];
  // },
  setPage(state, payload) {
    state.page = payload;
  },
  updatePages(state, payload) {
    const all_pages = state.all_pages.filter((page) => page.id !== payload.id);
    state.all_pages = [payload, ...all_pages];
    state.pages = [payload, ...all_pages]
      .filter((page) => page.is_active)
      .sort((a, b) => a.id > b.id);
  },

  setAllSlides(state, payload) {
    state.all_slides = [...payload];
  },
  setSlides(state, payload) {
    state.slides = [...payload];
  },
  setSlide(state, payload) {
    state.slide = payload;
  },
  updateSlides(state, payload) {
    const all_slides = state.all_slides.filter(
      (slide) => slide.id !== payload.id
    );
    state.all_slides = [payload, ...all_slides];
    state.slides = [payload, ...all_slides]
      .filter((slide) => slide.is_active)
      .sort((a, b) => a.id > b.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
