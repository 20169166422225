<template>
  <div class="page_title">
    <b-row>
      <b-col>
        <h3>
          <span v-if="icon" :class="icon"></span> {{ title }} <span v-if="addon">/ <small>{{ addon }}</small></span>
          <small v-if="back">
            <button v-if="!back_link" class="btn btn-link btn-sm" @click="$router.go(-1)"><i class="fas fa-arrow-left"></i> Palaa</button>
            <button v-else class="btn btn-link btn-sm" @click="$router.push(back_link)"><i class="fas fa-arrow-left"></i> Palaa</button>
          </small>
        </h3>
      </b-col>
      <b-col></b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    name: "PageTitle",
    props: ["addon", "back_link", "title", "icon", "back"]
  }
</script>

<style lang="sass" scoped>
  h3
    white-space: nowrap

</style>