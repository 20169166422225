<template>
  <div v-if="page">
    <b-row>
      <b-col>
        <PageTitle :title="page.page_name" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p class="text" v-html="page.page_desc" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageTitle from "@/components/generic/PageTitle";

export default {
  name: "Home",
  components: {
    PageTitle
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      is_desktop: "app/is_desktop",
      page: "content/page",
    }),
  },
  created() {
    this.$store.dispatch("content/getPage", { slug: this.$route.params.slug });
  }
};
</script>
