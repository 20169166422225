<template>
  <b-row>
    <b-col v-if="loading">
      <div class="text-center">
        <b-spinner
          style="width: 3rem; height: 3rem;"
          variant="primary"
          label="Loading"/>
      </div>
    </b-col>
    <b-col v-if="!loading">
      <div class="mt-3">
        <div class="items">
          <Item
            v-for="item in items"
            :item="item"
            :key="item.id"
            :is_desktop="is_desktop"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
// import router from "@/router";
import { mapGetters } from "vuex";

import Item from "./Item";

export default {
  name: "Items",
  components: {
    Item,
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapGetters({
      is_desktop: "app/is_desktop",
      items: "catalog/items",
    }),
  },
  created() {
    this.$store.dispatch("catalog/getItems").then(() => (this.loading = false));
  }
};

</script>

<style lang="sass">
// #carousel
//   margin-bottom: 24px

// .carousel-caption
//   background-color: rgb(37,37,37,0.4)

// .carousel-control-prev, .carousel-control-next
//   color: #333

// .fade
//   position: relative
//   height: 3.6em

.items
  display: flex
  flex-wrap: wrap
  margin-bottom: 40px
  .item
    background-color: #fff
    // min-width: 23%
    width: 336px
    margin-right: 3%
    margin-bottom: 3%
    // border: 1px solid #e2e2e2
    padding: 8px
    border-radius: 4px
    box-shadow: 0 1px 0 0 rgb(0 0 0 / 6%), 0 0 100px 0 rgb(0 0 0 / 10%)
    transition: box-shadow .2s ease-in-out

    &:hover
      box-shadow: 0 5px 15px 0 #ccc

    a
      text-decoration: none
      color: #333
      cursor: pointer

    .image
      // height: 170px
      display: flex
      justify-content: center
      align-items: center
      margin-bottom: 10px
      img
        max-width: 326px
        max-height: 326px
      .placeholder
        width: 100%
        height: 100%
        background-color: #e2e2e2
        border: 1px solid #d5d5d5
        display: flex
        justify-content: center
        align-items: center
        max-width: 210px
    .title
      margin-top: auto
      // padding: 10px 0
      font-weight: 600
      text-transform: uppercase
      overflow: hidden
      text-overflow: ellipsis

    .desc
      display: flex
      justify-content: space-between
      align-items: center

      .space
        font-weight: 600
        font-size: 1.125rem

@media (max-width: 688px)
  .items
    .item
      width: 100%
      max-width: 100%
      font0-size: 1.1rem
      .title
        margin-top: auto
        width: 100%
        max-width: 100%
</style>
