<template>
  <div>
    <div class="wrapper">
      <b-container>
        <Header />
        <Message
          v-for="message in messages"
          :key="message.id"
          :message="message"
          :closeMessage="closeMessage"
        />
        <!-- <keep-alive> -->
        <router-view />
        <!-- </keep-alive> -->
      </b-container>
    </div>
    <div id="footer">
      <div id="brand">
        <span class="company">
          <strong>REIPAS</strong>
          <div>Accommodations</div>
        </span>
      </div>
      <div id="c3">
        <!-- <ul>
          <li v-for="page in pages"
            :key="page.id"
            :page="page">
            <router-link :to="getUrl(page.page_slug)">{{ page.page_name }}</router-link>
          </li>
        </ul> -->
        <div>
          Tammikatu 16<br>
          48100 Kotka<br>
          reipas@sapokka.fi<br>
          +358 40 512 5232<br>

        </div>
      </div>

      <div id="c4">
        <!-- <a href="http://vk.com/finshopfi" target="_blank"><i class="fab fa-vk"></i></a> -->
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Header from "@/components/views/Header";
import Message from "@/components/generic/Message";
// import SearchBar from "@/components/generic/SearchBar";

export default {
  name: "App",
  components: {
    Header,
    Message,
    // SearchBar
  },
  data: () => ({
    is_admin: false
  }),
  computed: {
    ...mapGetters({
      messages: "app/messages",
      pages: "content/pages",
      isLoggedIn: "user/isLoggedIn",
      is_desktop: "app/is_desktop"
    }),
  },
  watch: {
    $route(to) {
      if (to.path.indexOf("/a/") !== -1) {
        this.is_admin = true;
      } else {
        this.is_admin = false;
      }
    }
  },
  methods: {
    closeMessage: function(message_id) {
      this.$store.dispatch("app/closeMessage", message_id);
    },
    getUrl(slug) {
      return `/p/${slug}`;
    }
  },
  created() {
    if (this.$route.path.indexOf("/a/") !== -1) {
      this.is_admin = true;
    } else {
      this.is_admin = false;
    }
  }
};
</script>
